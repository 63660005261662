import React from "react";

import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/lebensmittelvergiftung"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div>
                          <div id="search_form">
                            <input
                              type="text"
                              name="search_block_form"
                              id="searchword"
                              maxLength="128"
                            />
                            <input
                              type="submit"
                              name="suchen"
                              id="searchsubmit"
                              defaultValue
                            />
                            <input
                              type="hidden"
                              name="form_token"
                              defaultValue="fpRQ2dP7NgBmLR_3_abvt9HZCsr1ssctdnKOS8-vJyo"
                            />
                            <input
                              type="hidden"
                              name="form_id"
                              defaultValue="search_block_form"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="meta-text">
            IMODIUM®.&nbsp;Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
            </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/expertenrat/">Expertenrat</a>
                  <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p9">
                    Kontakt
                  </a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/wie-wirkt-imodium-akut/">Wie wirkt IMODIUM®?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727 active-trail">
                      <a
                        href="/fakten-uber-durchfall/"
                        className="active-trail"
                      >
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Diagnose Reizdarm</a>
                    </li>
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div
                  id="block-menu-menu-durchfall-ursachen-behand"
                  className="block block-menu"
                >
                  <h2>Durchfall - Ursachen &amp; Behandlung</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/fakten-uber-durchfall/" title>
                          Fakten über Durchfall
                        </a>
                      </li>
                      <li className="expanded">
                        <a href="/tipps-bei-durchfall/" title>
                          Tipps bei Durchfall
                        </a>
                        <ul className="menu">
                          <li className="first last leaf">
                            <a href="/tipps-fuer-den-winter/" title>
                              Tipps für den Winter
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="leaf">
                        <a href="/ursachen-von-durchfall/" title>
                          Ursachen von Durchfall
                        </a>
                      </li>
                      <li className="expanded active-trail">
                        <a
                          href="/richtig-essen/"
                          title
                          className="active-trail"
                        >
                          Gesunde Essgewohnheiten
                        </a>
                        <ul className="menu">
                          <li className="first leaf">
                            <a href="/nahrungsmittelallergie/" title>
                              Nahrungsmittelallergien und -unverträglichkeiten
                            </a>
                          </li>
                          <li className="leaf active-trail">
                            <a
                              href="/lebensmittelvergiftung/"
                              title
                              className="active-trail active"
                            >
                              Lebensmittelvergiftung
                            </a>
                          </li>
                          <li className="last leaf">
                            <a href="/rezepte/" title>
                              Gesunde Rezepte
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="expanded">
                        <a href="/stress-durchfall/" title>
                          Stress und Durchfall
                        </a>
                        <ul className="menu">
                          <li className="first leaf">
                            <a href="/hohe-arbeitsbelastung/" title>
                              Hohe Arbeitsbelastung
                            </a>
                          </li>
                          <li className="last leaf">
                            <a href="/prufungssituationen/" title>
                              Prüfungssituationen
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="leaf">
                        <a href="/chronischer-durchfall/" title>
                          Chronischer Durchfall
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/gastroenteritis/" title>
                          Magen-Darm-Grippe
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/norovirus/" title>
                          Norovirus
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/durchfall-wahrend-ihrer-periode/" title>
                          Durchfall während Ihrer Periode
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>              
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <h1>
                      <div className="field field-name-field-title-text field-type-text field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            Lebensmittelvergiftung{" "}
                          </div>
                        </div>
                      </div>
                    </h1>
                    <div className="headimg border">
                      <img
                        typeof="foaf:Image"
                        src="/sites/default/files/header_visuals_695x286_lebensmittelvergiftung.jpg"
                        width="695"
                        height="286"
                      />{" "}
                    </div>{" "}
                    <div className="std-site">
                      <div div className="firstrow">
                        <div className="left">
                          <div className="quote">
                            <span>
                              <h2>
                                <div className="field field-name-field-center-title field-type-text-long field-label-hidden">
                                  <div className="field-items">
                                    <div className="field-item even">
                                      Hilfe bei Lebensmittel- vergiftung
                                    </div>
                                  </div>
                                </div>{" "}
                              </h2>
                            </span>
                          </div>
                        </div>
                        <div className="right">
                          <p className="subline">
                            Eine Lebensmittelvergiftung wird durch den Verzehr
                            giftiger, verdorbener, oder mit Keimen verunreinigter Nahrungsmittel
                            hervorgerufen. In der Regel sind Giftstoffe, die von
                            Bakterien gebildet werden, die Ursache für
                            Lebensmittelvergiftungen. Diese sind häufig in Milch
                            und Eierprodukten enthalten, darum ist bei der
                            Aufnahme dieser Nahrungsmittel besondere Vorsicht
                            geboten. Zudem können Lebensmittel Vergiftungen auslösen, die natürlicherweise Toxine (Gifte) enthalten, wie z.B. Pilze.{" "}
                          </p>
                        </div>
                        <div className="clear" />
                      </div>
                      <div div className="secondrow">
                        <div className="left">
                          <div className="field field-name-field-sidebar-text field-type-text-with-summary field-label-hidden">
                            <div className="field-items">
                              <div className="field-item even">
                                <h3>Behandlung</h3>
                                <p>
                                  Verspüren Sie Anzeichen einer
                                  Lebensmittelvergiftung, ist es sinnvoll, die
                                  Beschwerden von einem Arzt abklären zu lassen.
                                  Unbedingt angeraten ist ein Arztbesuch, wenn
                                  bei Kindern, älteren Menschen und Personen mit
                                  geschwächtem Immunsystem der Verdacht auf eine
                                  Lebensmittelvergiftung besteht oder wenn
                                  Fieber und blutiger Durchfall auftreten.
                                </p>
                                <p>
                                  Da der Körper bei Durchfall und Erbrechen viel
                                  Flüssigkeit und Elektrolyte verliert, ist es
                                  wichtig, viel zu trinken. Zudem sind in der
                                  Apotheke spezielle Elektrolytmischungen
                                  verfügbar, die dem Körper die wertvollen
                                  Stoffe wieder zuführen.
                                </p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                        <div className="right">
                          <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                            <div className="field-items">
                              <div
                                className="field-item even"
                                property="content:encoded"
                              >
                                <p>
                                  Oft kommt es bei Lebensmittelvergiftungen zu
                                  Symptomen wie Übelkeit, Erbrechen,
                                  krampfartigen Bauchschmerzen und Durchfall.
                                  Diese treten in der Regel 1 bis 16 Stunden
                                  nach Verzehr auf und dauern bis zu 2 Tage an.
                                </p>
                                <h3>Vorbeugung</h3>
                                <ul>
                                  <li>
                                    Waschen Sie sich vor und nach der
                                    Zubereitung von Essen die Hände.
                                  </li>
                                  <li>
                                    Achten Sie darauf, Fleisch, Geflügel und
                                    Fisch nicht auf dem gleichen Brett zu
                                    schneiden wie Gemüse.
                                  </li>
                                  <li>
                                    Reinigen Sie Arbeitsflächen und Küchengeräte
                                    nach jedem Gebrauch.
                                  </li>
                                  <li>
                                    Beachten Sie das Ablaufdatum bei
                                    Lebensmitteln.
                                  </li>
                                  <li>
                                    Verwenden Sie nur frische rohe Eier, z.B.
                                    für Mayonnaise oder Cremespeisen – diese
                                    Speisen sollten schnell verzehrt werden.
                                  </li>
                                  <li>
                                    Möchten Sie Fleisch oder Gemüse einkochen,
                                    sollten Sie es vorsorglich zweimal erhitzen.
                                  </li>
                                  <li>
                                    Achten Sie gerade im Sommer auf einen
                                    sogfältigen Umgang mit Lebensmitteln, da
                                    Lebensmittel bei warmen Temperaturen
                                    schneller verderben.
                                  </li>
                                  <li>
                                    Entsorgen Sie bereits aufgeblähte
                                    Konservendosen, da diese mit dem
                                    Botulismus-Erreger kontaminiert sein können.
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                        <div className="clear" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <div id="footer">
          <div className="wrap">
            <div className="sitemap">
              <div className="col1">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/wie-wirkt-imodium-akut/" title>
                      Wie wirkt IMODIUM®?
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/loperamid/" title>
                      Der Wirkstoff: Loperamid
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last expanded">
                    <a href="/imodium-produkte/" title>
                      Produktübersicht
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/imodium-akut-schmelztabletten/" title>
                          IMODIUM® akut Schmelztabletten
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/imodium-kapseln/" title>
                          IMODIUM® Kapseln
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="col2">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/fakten-uber-durchfall/" title>
                      Fakten über Durchfall
                    </a>
                  </li>
                  <li className="expanded">
                    <a href="/tipps-bei-durchfall/" title>
                      Tipps bei Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first last leaf">
                        <a href="/tipps-fuer-den-winter/" title>
                          Tipps für den Winter
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/ursachen-von-durchfall/" title>
                      Ursachen von Durchfall
                    </a>
                  </li>
                  <li className="expanded active-trail">
                    <a href="/richtig-essen/" title className="active-trail">
                      Gesunde Essgewohnheiten
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/nahrungsmittelallergie/" title>
                          Nahrungsmittelallergien und -unverträglichkeiten
                        </a>
                      </li>
                      <li className="leaf active-trail">
                        <a
                          href="/lebensmittelvergiftung/"
                          title
                          className="active-trail active"
                        >
                          Lebensmittelvergiftung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/rezepte/" title>
                          Gesunde Rezepte
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="expanded">
                    <a href="/stress-durchfall/" title>
                      Stress und Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/hohe-arbeitsbelastung/" title>
                          Hohe Arbeitsbelastung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/prufungssituationen/" title>
                          Prüfungssituationen
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/chronischer-durchfall/" title>
                      Chronischer Durchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/gastroenteritis/" title>
                      Magen-Darm-Grippe
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/norovirus/" title>
                      Norovirus
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/durchfall-wahrend-ihrer-periode/" title>
                      Durchfall während Ihrer Periode
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col3">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/diagnose-reizdarm/" title>
                      Diagnose Reizdarm (IBS)
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/behandlung-eines-reizdarms/" title>
                      Behandlung eines Reizdarms
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/reizdarm-ernahrung/" title>
                      Ernährung bei Reizdarm
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/reisedurchfall-vorbeugen/" title>
                      Vorbeugung von Reisedurchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/reiseapotheke/" title>
                      Reiseapotheke
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/reisedurchfall-risikofaktoren/" title>
                      Reisedurchfall Risikofaktoren
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/reisedurchfall-infos-und-fakten/" title>
                      Infos &amp; Fakten zu Reisedurchfall
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col4">
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/durchfall-bei-kindern/" title>
                      Durchfall bei Kindern
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/infomaterial/" title>
                      Infomaterial
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/faq-page/" title>
                      FAQ
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/glossar/" title>
                      Glossar
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="clear" />
            </div>
            <div className="subnavi">
              <div className="region region-metanavi">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/impressum/" title>
                      Impressum
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/kontakt/" title>
                      Kontakt
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/rechtliche-hinweise/" title>
                      Nutzungsbedingungen
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/datenschutz/" title>
                      Datenschutz
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/cookie-richtlinien/" title>
                      Cookie-Richtlinien
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                   <p>
                     IMODIUM®. Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
                  </p>
                  <p>
                    <br />© Johnson &amp; Johnson GmbH 2011-2019. Diese Website
                    wird von der Johnson &amp; Johnson GmbH veröffentlicht, die
                    allein für deren Inhalte verantwortlich ist. Sie ist für
                    Besucher aus Österreich bestimmt.
                    <br />
                    Letzte Aktualisierung am: 23. Dezember 2021, AT-IM-2100045
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n<!--//--><!--[CDATA[//--><!--\nfunction euCookieComplianceLoadScripts() {}\n//--><!--]]-->\n"
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--//--><!--[CDATA[//--><!--\nvar eu_cookie_compliance_cookie_name = "";\n//--><!--]]-->\n'
          }}
        />
        <script
          type="text/javascript"
          src="/sites/all/modules/contrib/eu_cookie_compliance/js/eu_cookie_compliance.js?qbpk52"
        />
      </div>
    );
  }
}

export default Page;
